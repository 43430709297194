import AuthService from 'src/services/AuthService'
import TokenService from 'src/services/TokenService'
import React from 'react'
import { CRow } from '@coreui/react'

export const tokenHeaderGenerator = () => {
  const header = {
    Authorization: `Bearer ${TokenService.getToken()}`,
  }
  return header
}

export const truncateString = (inputString, maxLength) => {
  if (inputString.length > maxLength) {
    return inputString.slice(0, maxLength) + '...';
  }
  return inputString;
}

export function removeSpaces(inputString) {
  // Use a regular expression to replace all whitespace characters with an empty string
  return inputString.replace(/\s+/g, "");
}


export const extractNumbers = (reference) => {
  return reference.replace(/\D/g, "");
}

export const randomFingetPrint = () => {
  const randomString = (Math.random() + 1).toString(36).substring(7)
  return [
    {
      room: 'PC1_Bridge',
      templateStr: randomString + '1',
      userFignerIndex: 1,
    },
    {
      room: 'PC1_Bridge',
      templateStr: randomString + '2',
      userFignerIndex: 2,
    },
  ]
}

export const generateRandomNumber = (digits) => {
  var min = Math.pow(10, digits - 1) // Minimum value based on number of digits
  var max = Math.pow(10, digits) - 1 // Maximum value based on number of digits
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function isAnyAttributeEmpty(obj) {
  const keys = Object.keys(obj)

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const value = obj[key]

    if (value === null || value === undefined || value === '') {
      console.log(key)
      return true // At least one attribute is empty
    }
  }

  return false // No empty attributes found
}

export const navigationLevelChecker = async () => {
  const navigation = JSON.parse(localStorage.getItem('navigation'))
  const userLevel = TokenService.getUserLevel()

  navigation.forEach((mainHeader) => {
    mainHeader.items.forEach((item) => {
      if (item?.level.includes(Number(userLevel))) item.show = true
      else item.show = false
    })
  })

  localStorage.setItem('navigation', JSON.stringify(navigation))
}

export const getFingerPrintKey = () => {
  return localStorage.getItem('cloud-care-fingerprint-key')
}

export const setFingerPrintKey = (key) => {
  return localStorage.setItem('cloud-care-fingerprint-key', key)
}


export const ReceiptGenerator = async ({data}) => {
  return (   <div>
    <CRow style={{ left: "50%" }}>
        <span style={{ textAlign: 'center', paddingTop: "4mm", fontWeight: 'bolder' }}>{data?.serialNo || '-'}</span>
        <span style={{ paddingLeft: "128mm", paddingTop: "35mm", fontWeight: 'bolder' }}>{data?.refNo +  data?.countryRef?.code || '-'}</span>
        <span style={{ paddingTop: "130mm", paddingLeft: "70mm" }}>{data?.honorificRef?.honorific}{' '}{data?.firstName || '-'} {' '} {data?.lastName}</span>
        <span style={{ paddingTop: "7mm", display: 'flex', justifyContent: "space-between" }}>
            <span style={{ paddingLeft: "85mm" }}>{data?.placeOfBirthRef?.city || '-'}</span>
            <span style={{ paddingRight: "40mm" }}>{data?.age || '-'}</span>
        </span>
        <span style={{ paddingTop: "7mm", paddingLeft: "70mm" }}>{data?.passport || '-'}</span>
        <span style={{ paddingTop: "8mm", display: 'flex', justifyContent: "space-between" }}>
            <span style={{ paddingLeft: "60mm" }}>{data?.status || '-'}</span>
            <span style={{ paddingRight: "40mm" }}>{data?.contactNo ? ' - ' : data?.contactNo }</span>
        </span>
        <span style={{ paddingTop: "6mm", display: 'flex', justifyContent: "space-between" }}>
            <span style={{ paddingLeft: "65mm" }}>{data?.nationalityRef?.nationality || '-'}</span>
            <span style={{ paddingRight: "43mm" }}>{data?.countryRef?.country || '-'}</span>
        </span>
        <span style={{ paddingTop: "7mm", paddingLeft: "60mm" }}>{data?.address || '-'}</span>
        <span style={{ paddingTop: "7mm", paddingLeft: "70mm" }}>{data?.positionRef?.position || '-'}</span>
        <span style={{ paddingTop: "10mm", paddingLeft: "50mm" }}>{data?.registeredDate || '-'}</span>

    </CRow>
</div>)
}