//import axios from "axios";


import axios from "axios";

import TokenService from "./TokenService";
import { API } from "src/common/const";
import { tokenHeaderGenerator } from "src/common/common";
import Swal from 'sweetalert'


const registerUser = async (data) => {
  const response = await axios.post(API.USER_REGISTER, data, {
    headers: tokenHeaderGenerator(),
  });

  return response;
};

const updateUser = async (id, data) => {
  const response = await axios.patch(API.USER_REGISTER + "/" + id, data, {
    headers: tokenHeaderGenerator(),
  });

  return response;
};

const getUsersAll = async () => {
  const response = await axios.get(API.USER_REGISTER, {
    headers: tokenHeaderGenerator(),
  });

  return response;
};


const getUser = async (id) => {
  const response = await axios.get(API.USER_REGISTER + "/" + id, {
    headers: tokenHeaderGenerator(),
  });

  return response;
};

const login = async (name, password) => {
  try {

    const response = await axios.post(API.LOGIN, {
      username: name,
      password,
    });
    //console.log("user ->", response.data);
    if (response.data.access_token) {
      // response.data['level'] = 1
      TokenService.setUser(response.data);
    }
    return response;
  } catch (e) {
    console.log(e)
    throw e
  }
};

const logout = async () => {
  TokenService.removeUser();

  // const response = await axios.post("/auth/signOut");

};

const getCurrentUser = () => {

  ////////////////console.log("here 11")
  return JSON.parse(localStorage.getItem("user-cloud-care"));
};

const AuthService = {
  registerUser,
  updateUser,
  login,
  logout,
  getCurrentUser,
  getUsersAll,
  getUser
};

export default AuthService;
