import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { CImage, CModal, CModalBody } from '@coreui/react'
import Spinner from '../../assets/spinner.svg'

const LoadingModal = () => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use((config) => {
      setIsLoading(true)
      return config
    })

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        setIsLoading(false)
        return response
      },
      (error) => {
        setIsLoading(false)
        return Promise.reject(error)
      },
    )

    return () => {
      axios.interceptors.request.eject(requestInterceptor)
      axios.interceptors.response.eject(responseInterceptor)
    }
  }, [])

  return (
    <CModal
      style={{
        marginTop: '40%',
        padding: '5%',
        alignItems: 'center',
        display: 'flex',
        boxShadow: '0 18px 38px rgba(0,0,0,0.30), 0 150px 1000px rgba(0,0,0,0.22)',
      }}
      visible={isLoading}
      backdrop={false}
      //style={{boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'}}
    >
      <CModalBody>
        {isLoading && (
          <div className="loading-modal">
            <div className="loading-spinner">
                <CImage src={Spinner} height={100} />
            </div>
            <p style={{fontWeight: 'bold', fontSize: "1.3em", textAlign: "center"}}>Loading...</p>
          </div>
        )}
      </CModalBody>
    </CModal>
  )
}

export default LoadingModal
