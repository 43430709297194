import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import AuthService from './services/AuthService'
import MainHeader from './components/MainHeader'
import LoadingModal from './components/Modals/LoadingModal'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Home = React.lazy(() => import('./views/pages/home/Home'))
const PhotoCapture = React.lazy(() => import('./layout/PhotoCapture'))
const Register = React.lazy(() => import('./layout/Register'))
const MedicalDelivery = React.lazy(() => import('./layout/MedicalDelivery'))
const LabRemarks = React.lazy(() => import('./layout/LabRemarks'))
const PRODoctorRemarks = React.lazy(() => import('./layout/PRODoctorRemarks'))
const MedicalResultEntry = React.lazy(() => import('./layout/MedicalResultEntry'))
const MedicalInquires = React.lazy(() => import('./layout/MedicalInquires'))
const EnjazInquires = React.lazy(() => import('./layout/EnjazInquires'))
const CountryMaster = React.lazy(() => import('./layout/CountryMaster'))
const AgencyMaster = React.lazy(() => import('./layout/AgencyMaster'))
const DoctorMaster = React.lazy(() => import('./layout/DoctorMaster'))
const TreatmentMaster = React.lazy(() => import('./layout/TreatmentMaster'))
const FingerVerification = React.lazy(() => import('./layout/FingerVerification'))
const MedicalIssues = React.lazy(() => import('./layout/MedicalIssues'))
const PaymentPage = React.lazy(() => import('./layout/PaymentPage'))
const LabReports = React.lazy(() => import('./layout/LabReports'))
const OPDReports = React.lazy(() => import('./layout/OPDReports'))
const ManagementReport = React.lazy(() => import('./layout/ManagementReport'))
const NormalMedicalReport = React.lazy(() => import('./layout/NormalMedicalReport'))
const ApprovalPage = React.lazy(() => import('./layout/ApprovalPage'))
const DailySalesSummeryReports = React.lazy(() => import('./layout/DailySalesSummeryReports'))
const UserRegistration = React.lazy(() => import('./layout/UserRegistration'))
const Settings = React.lazy(() => import('./layout/Settings'))
const ActivityLogs = React.lazy(() => import('./layout/ActivityLogs'))
const MedicalList = React.lazy(() => import('./layout/MedicalList'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {
  const [userLogin, setUserLogin] = useState(false)

  useEffect(() => {
    const user = AuthService.getCurrentUser()

    if (user) {
      setUserLogin(true)
    } else {
      logOut()
    }
  }, [])

  const logOut = () => {
    AuthService.logout()
    setUserLogin(false)
    // change status
  }

  return (
    <>
      <LoadingModal />
      <MainHeader />
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            {/* <Route exact path="/login" name="Login Page" element={userLogin ? <Login /> : <DefaultLayout />} /> */}
            <Route
              exact
              path="/register"
              name="Register Page"
              element={userLogin ? <Register /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/photo-capture"
              name="Photo Page"
              element={userLogin ? <PhotoCapture /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/delivery"
              name="Medical Delivery Page"
              element={userLogin ? <MedicalDelivery /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/lab-remark"
              name="Lab Remarks Page"
              element={userLogin ? <LabRemarks /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/pro-doctor-remark"
              name="PRO Doctor Remarks Page"
              element={userLogin ? <PRODoctorRemarks /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/medical-result-entry"
              name="Medical Result Entry Page"
              element={userLogin ? <MedicalResultEntry /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/medical-inquires"
              name="Medical Inquires Page"
              element={userLogin ? <MedicalInquires /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/enjaz-inquires"
              name="ENJAZ Inquires Page"
              element={userLogin ? <EnjazInquires /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/country-master"
              name="Country Master Page"
              element={userLogin ? <CountryMaster /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/doctor-master"
              name="Doctor Master Page"
              element={userLogin ? <DoctorMaster /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/agency-master"
              name="Doctor Master Page"
              element={userLogin ? <AgencyMaster /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/treatment-master"
              name="Treatment Master Page"
              element={userLogin ? <TreatmentMaster /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/finger-verification"
              name="Finger Verification Page"
              element={userLogin ? <FingerVerification /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/medical-issues"
              name="Medical Issues Page"
              element={userLogin ? <MedicalIssues /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/lab-reports"
              name="Lab Reports Page"
              element={userLogin ? <LabReports /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/management-reports"
              name="Management Reports Page"
              element={userLogin ? <ManagementReport /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/opd-reports"
              name="OPD Reports Page"
              element={userLogin ? <OPDReports /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/normal-medical-reports"
              name="Normal Medical Reports Page"
              element={userLogin ? <NormalMedicalReport /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/daily-sales-reports"
              name="Daily Sales Reports Page"
              element={userLogin ? <DailySalesSummeryReports /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/medical-list-reports"
              name="Medical List Reports Page"
              element={userLogin ? <MedicalList /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/payments"
              name="Payment Page"
              element={userLogin ? <PaymentPage /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/approval"
              name="Approval Page"
              element={userLogin ? <ApprovalPage /> : <DefaultLayout />}
            />
            <Route
              exact
              path="/user"
              name="Approval Page"
              element={userLogin ? <UserRegistration /> : <DefaultLayout />}
            />

            <Route
              exact
              path="/settings"
              name="Settings Page"
              element={userLogin ? <Settings /> : <DefaultLayout />}
            />

            <Route
              exact
              path="/logs"
              name="Logs Page"
              element={userLogin ? <ActivityLogs /> : <DefaultLayout />}
            />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={userLogin ? <Home /> : <DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </>
  )
}

export default App
