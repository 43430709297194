const MAIN_API = process.env.REACT_APP_MAIN_API

export const MAIN_API_FOR_PHOTO = MAIN_API
export const FINGERPRINT_API = process.env.REACT_APP_FINGERPRINT_API
const redeploy = true

export const FINGERPRINT_STATUS = true
export const COMPANY_NAME = "Ohlums Clinic & Laboratories(PVT) LTD"
export const API = {
  LOGIN: MAIN_API + 'auth/login',
  MEDICAL_REGISTER: MAIN_API + 'medical-register',
  MEDICAL_TYPE: MAIN_API + 'medical-type',
  MEDICAL_ISSUES: MAIN_API + 'medical-issues',
  LAB_REMARK: MAIN_API + 'lab-remark',
  DOCTOR_REMARK: MAIN_API + 'doctor-remark',
  RESULT_ENTRY: MAIN_API + 'result-entries',
  COUNTRY: MAIN_API + 'country',
  AGENCY: MAIN_API + 'agency',
  DOCTOR: MAIN_API + 'doctor',
  TREATMENT: MAIN_API + 'medical-certificate',
  POSITION: MAIN_API + 'position',
  HONORIFIC: MAIN_API + 'honorific',
  CITY: MAIN_API + 'city',
  NATIONALITY: MAIN_API + 'nationality',
  DELIVERY: MAIN_API + 'delivery',
  REPORTS: MAIN_API + 'reports',
  MEDICAL_TEST_REPORTS: MAIN_API + 'reports/medical-test-reports',
  PHOTO: MAIN_API + 'gallery',
  PAYMENT: MAIN_API + 'payment',
  MMR_PAYMENT: MAIN_API + 'mmr-payment',
  USER_REGISTER: MAIN_API + 'user',
  FINGERPRINT: MAIN_API + 'finger-print',
  ENJAZ: MAIN_API + 'enjaz-details',
  LOGS: MAIN_API + 'activity-log',
}

export const FINFERPRINT_COMMON_MESSAGES = {
  FAIL_IDENTITY : "FAILED IDENTIFYING  USER" ,
  IDENTIFY: "IDENTIFYING USER",
  RESET_AGAIN: "IDENTIFYING RESET FOR NEW",
  SUCCESS_IDENTIFYING: "SUCCESS IDENTIFYING  USER",
  ENROLL_NEW_USER: "ENROLLING NEW USER",
  ENROLL_RESET_AGAIN: "ENROLLING RESET FOR NEW",
  ENROLL_SUCCESS_L: "ENROLL SUCCESS ON LEFT THUMB",
  ENROLL_SUCCESS_R: "ENROLL SUCCESS ON RIGHT THUMB"
}

export const LOGS = {
  ACTION_TYPES: {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete' 
  }
}

export const honorifics = [
  'MR.',
  'MS.',
  'MRS.',
  'MISS.',
  'MASTER.',
  'BABY.',
  'SIS.',
  'DR.',
  'REV.',
  'MOULAVI',
]

export const user_status = [
  {
    label: "MARRIED",
    value: "MARRIED"
  },
  {
    label: "SINGLE",
    value: "SINGLE"
  },
  {
    label: "WIDOWED",
    value: "WIDOWED"
  }
]

// export const user_status = ['MARRIED', 'SINGLE', 'WIDOWED']

export const places = [
  { value: "AMPARA", label: "Ampara" },
  { value: "ANURADHAPURA", label: "Anuradhapura" },
  { value: "BADULLA", label: "Badulla" },
  { value: "BATTICALOA", label: "Batticaloa" },
  { value: "COLOMBO", label: "Colombo" },
  { value: "GALLE", label: "Galle" },
  { value: "GAMPAHA", label: "Gampaha" },
  { value: "HAMBANTOTA", label: "Hambantota" },
  { value: "JAFFNA", label: "Jaffna" },
  { value: "KANDY", label: "Kandy" },
  { value: "KEGALLE", label: "Kegalle" },
  { value: "KILINOCHCHI", label: "Kilinochchi" },
  { value: "KURUNEGALA", label: "Kurunegala" },
  { value: "MANNAR", label: "Mannar" },
  { value: "MATALE", label: "Matale" },
  { value: "MATARA", label: "Matara" },
  { value: "MONERAGALA", label: "Moneragala" },
  { value: "MULLAITIVU", label: "Mullaitivu" },
  { value: "NUWARA ELIYA", label: "Nuwara Eliya" },
  { value: "PLONNARUWA", label: "Plonnaruwa" },
  { value: "PUTTALAM", label: "Puttalam" },
  { value: "RATNAPURA", label: "Ratnapura" },
  { value: "TRINCOMALEE", label: "Trincomalee" },
  { value: "VAVUNIYA", label: "Vavuniya" }
];

export const navigationPanel = [
  {
    name: 'Data Capture',
    items: [
      {
        name: 'Register',
        icon: 'person_add',
        href: '/#/register',
        isBookMarked: false,
        level: [4, 5, 6, 8, 9, 10],
        show: true,
      },
      // {
      //     name: "Pending Entry",
      //     icon: 'pending_actions',
      //     href: "#",
      //     isBookMarked: false
      // },
      {
        name: 'Management Approval',
        icon: 'pending_actions',
        href: '/#/approval',
        isBookMarked: false,
        level: [5, 4, 6, 8, 2, 9, 10],
        show: true,
      },
      {
        name: 'Lab Remark',
        icon: 'lab_research',
        href: '/#/lab-remark',
        isBookMarked: false,
        level: [4, 6, 7, 8, 9, 10],
        show: true,
      },
      {
        name: 'Medical Issues',
        icon: 'medical_information',
        href: '/#/medical-issues',
        isBookMarked: false,
        level: [8, 9, 10],
        show: true,
      },
      // {
      //     name: "OPD Entry",
      //     icon: 'medical_services',
      //     href: "#",
      //     isBookMarked: false
      // },
      {
        name: 'PRO & Doctor',
        icon: 'stethoscope',
        href: '/#/pro-doctor-remark',
        isBookMarked: false,
        level: [4, 6, 1, 8, 9, 10],
        show: true,
      },
      // {
      //     name: "Malasia Medical",
      //     icon: 'medical_services',
      //     href: "#",
      //     isBookMarked: false,
      //     level: 1
      // },
      // {
      //     name: "Test Find",
      //     icon: 'labs',
      //     href: "#",
      //     isBookMarked: false
      // },
      {
        name: 'Result Entry',
        icon: 'lab_profile',
        href: '/#/medical-result-entry',
        isBookMarked: false,
        level: [6, 7, 8, 9, 10],
        show: true,
      },
      // {
      //     name: "Saudi Medical Details",
      //     icon: 'medical_services',
      //     href: "#",
      //     isBookMarked: false
      // },
      // {
      //     name: "Other Country Medical Entry",
      //     icon: 'medical_services',
      //     href: "#",
      //     isBookMarked: false
      // },
      {
        name: 'Delivery',
        icon: 'local_shipping',
        href: '/#/delivery',
        isBookMarked: false,
        level: [2, 4, 5, 6, 8, 9, 10],
        show: true,
      },
      {
        name: 'Payments',
        icon: 'credit_card',
        href: '/#/payments',
        isBookMarked: false,
        level: [5, 4, 6, 8, 2, 9, 10],
        show: true,
      },
      // {
      //     name: "Credit Authorization",
      //     icon: 'account_balance',
      //     href: "#",
      //     isBookMarked: false
      // },
      // {
      //     name: "OPD Patient Test Result Entry",
      //     icon: 'personal_injury',
      //     href: "#",
      //     isBookMarked: false
      // },
      // {
      //     name: "X-Ray",
      //     icon: 'radiology',
      //     href: "#",
      //     isBookMarked: false
      // },
      {
        name: 'Finger Verification',
        icon: 'fingerprint',
        href: '#/finger-verification',
        isBookMarked: false,
        level: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        show: true,
      },
      // {
      //     name: "Laboratory Sample Collection",
      //     icon: 'science',
      //     href: "#",
      //     isBookMarked: false,
      //     level: 1
      // },
      // {
      //     name: "Invoice",
      //     icon: 'receipt_long',
      //     href: "#",
      //     isBookMarked: false
      // },
      // {
      //     name: "E No",
      //     icon: 'receipt',
      //     href: "#",
      //     isBookMarked: false
      // },
      {
        name: 'Photo Capture',
        icon: 'camera',
        href: '/#/photo-capture',
        isBookMarked: false,
        level: [2, 3, 4, 5, 6, 8, 9, 10],
        show: true,
      },
    ],
  },
  {
    name: 'Inquires',
    items: [
      {
        name: 'Passport Inquiry',
        icon: 'badge',
        href: '#/medical-inquires',
        isBookMarked: false,
        level: [4, 6, 8, 9, 10],
        show: true,
      },
      {
        name: 'ENJAZ Inquiry',
        icon: 'badge',
        href: '#/enjaz-inquires',
        isBookMarked: false,
        level: [4, 6, 8, 9, 10],
        show: true,
      },
      // {
      //     name: "Ledger",
      //     icon: 'badge',
      //     href: "#",
      //     isBookMarked: false
      // },
      // {
      //     name: "Find",
      //     icon: 'search',
      //     href: "#",
      //     isBookMarked: false
      // }
    ],
  },
  {
    name: 'Master Files',
    items: [
      // {
      //     name: "Lab Test Master Files",
      //     icon: 'labs',
      //     href: "#",
      //     isBookMarked: false
      // },
      {
        name: 'Doctor Master Files',
        icon: 'stethoscope',
        href: '#/doctor-master',
        isBookMarked: false,
        level: [4, 8, 9, 10],
        show: true,
      },
      {
        name: 'Agency Master Files',
        icon: 'manage_accounts',
        href: '/#/agency-master',
        isBookMarked: false,
        level: [4, 8, 9, 10],
        show: true,
      },
      {
        name: 'Medical Master Files',
        icon: 'medical_information',
        href: '#/treatment-master',
        isBookMarked: false,
        level: [4, 8, 9, 10],
        show: true,
      },
      {
        name: 'Country Master Files',
        icon: 'flag',
        href: '#/country-master',
        isBookMarked: false,
        level: [4, 8, 9, 10],
        show: true,
      },
      // {
      //     name: "Master",
      //     icon: 'moving_ministry',
      //     href: "#",
      //     isBookMarked: false
      // }
    ],
  },
  {
    name: 'Reports',
    items: [
      {
        name: 'Lab Reports',
        icon: 'labs',
        href: '/#/lab-reports',
        isBookMarked: false,
        level: [7, 9, 10],
        show: true,
      },
      // {
      //     name: "OPD Reports",
      //     icon: 'prescriptions',
      //     href: "/#/opd-reports",
      //     isBookMarked: false
      // },
      // {
      //     name: "Commission Reports",
      //     icon: 'lab_profile',
      //     href: "#",
      //     isBookMarked: false
      // },
      // {
      //   name: 'Agent Reports',
      //   icon: 'clinical_notes',
      //   href: '#',
      //   isBookMarked: false,
      //   level: [8, 9, 10],
      //   show: true,
      // },
      {
        name: 'Medical List with details',
        icon: 'admin_meds',
        href: '/#/medical-list-reports',
        isBookMarked: false,
        level: [8, 9, 10],
        show: true,
      },
      // {
      //   name: 'Medicals',
      //   icon: 'admin_meds',
      //   href: '#',
      //   isBookMarked: false,
      //   level: [8, 9, 10],
      //   show: true,
      // },
      // {
      //   name: 'Outstanding Statement',
      //   icon: 'lab_profile',
      //   href: '#',
      //   isBookMarked: false,
      //   level: [8, 9, 10],
      //   show: true,
      // },
      // {
      //     name: "Saudi Embassy Report",
      //     icon: 'lab_profile',
      //     href: "#",
      //     isBookMarked: false
      // },
      // {
      //     name: "Saudi Lab Report",
      //     icon: 'lab_profile',
      //     href: "#",
      //     isBookMarked: false
      // },
      // {
      //   name: 'Normal Medical Report',
      //   icon: 'lab_profile',
      //   href: '/#/normal-medical-reports',
      //   isBookMarked: false,
      //   level: [8, 9, 10],
      //   show: true,
      // },
      {
        name: 'Management Reports',
        icon: 'lab_profile',
        href: '/#/management-reports',
        isBookMarked: false,
        level: [8, 9, 10],
        show: true,
      },
      // {
      //     name: "SAUDI Medical List",
      //     icon: 'lab_profile',
      //     href: "#",
      //     isBookMarked: false
      // },
      // {
      //   name: 'Unfit Report',
      //   icon: 'lab_profile',
      //   href: '#',
      //   isBookMarked: false,
      //   level: [8, 9, 10],
      //   show: true,
      // },
      // {
      //   name: 'Daily Sales Summery',
      //   icon: 'lab_profile',
      //   href: '/#/daily-sales-reports',
      //   isBookMarked: false,
      //   level: [8, 9, 10],
      //   show: true,
      // },
    ],
  },
  {
    name: 'Setting Entry',
    items: [
      {
        name: 'Settings',
        icon: 'settings',
        href: '#',
        isBookMarked: false,
        level: [9, 10],
        show: true,
      },
      // {
      //   name: 'Parameter Master File',
      //   icon: 'settings',
      //   href: '#',
      //   isBookMarked: false,
      //   level: [9, 10],
      //   show: true,
      // },
      // {
      //   name: 'Prise - LAB',
      //   icon: 'account_balance_wallet',
      //   href: '#',
      //   isBookMarked: false,
      //   level: [9, 10],
      //   show: true,
      // },
      {
        name: 'User Accounts',
        icon: 'manage_accounts',
        href: '/#/user',
        isBookMarked: false,
        level: [9, 10],
        show: true,
      },
      {
        name: 'Activity Logs',
        icon: 'logs',
        href: '/#/logs',
        isBookMarked: false,
        level: [9, 10],
        show: true,
      },
      // {
      //   name: 'Audit Trail',
      //   icon: 'account_balance_wallet',
      //   href: '#',
      //   isBookMarked: false,
      //   level: [9, 10],
      //   show: true,
      // },
      // {
      //   name: 'OP Bal',
      //   icon: 'account_balance_wallet',
      //   href: '#',
      //   isBookMarked: false,
      //   level: [9, 10],
      //   show: true,
      // },
      {
        name: 'Passwords',
        icon: 'password',
        href: '#',
        isBookMarked: false,
        level: [9, 10],
        show: true,
      },
    ],
  },
]
